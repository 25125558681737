import useReport from "../../../Hooks/useReport";

import Filters from "./Filters";

import Ads from "./Ads";
import Sources from "./Sources";
import SourcesNewClient from "./SourcesNewClient";

import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { useEffect, useState } from "react";
import { Text } from "../../../Components/Text";

import { formatarReal } from "../../../Components/utilities/Format";

const DashboardProspects = () => {
  const { indexLeadsByDate, indexAppoitmentsByDate } = useReport();

  const [indexLeads, setIndexLeads] = useState();
  const [indexAppoitments, setIndexAppoitments] = useState();

  const [indexNewClients, setIndexNewClients] = useState();
  const [totalNewClients, setTotalNewClients] = useState(0);
  const [indexAds, setIndexAds] = useState();
  const [indexSources, setIndexSources] = useState();
  const [countLeads, setCountLeads] = useState(0);

  // Variaveis das taxas de conversão
  const [countAppointments, setCountAppointments] = useState(0);
  const [countEvaluations, setCountEvaluations] = useState(0);
  const [countNewClients, setCountNewClients] = useState(0);

  // Taxas de conversão
  const [txConvOpAg, setTxConvOpAg] = useState(0);
  const [txConvAgEv, setTxConvAgEv] = useState(0);
  const [txConvEvFc, setTxConvEvFc] = useState(0);
  const [txConvTotal, setTxConvTotal] = useState(0);

  const handleFilter = (params) => {
    indexLeadsByDate(params, setIndexLeads);
    indexAppoitmentsByDate(params, setIndexAppoitments);
  };

  useEffect(() => {
    if (countLeads && countAppointments) {
      let conv_op_ag = (countAppointments / countLeads) * 100;
      setTxConvOpAg(conv_op_ag);
    }
  }, [countLeads, countAppointments]);

  useEffect(() => {
    if (countAppointments && countEvaluations) {
      let conv_ap_ev = (countEvaluations / countAppointments) * 100;
      setTxConvAgEv(conv_ap_ev);
    }
  }, [countAppointments, countEvaluations]);

  useEffect(() => {
    if (countEvaluations && countNewClients) {
      let conv_ev_nc = (countNewClients / countEvaluations) * 100;
      setTxConvEvFc(conv_ev_nc);
    }
  }, [countEvaluations, countNewClients]);

  useEffect(() => {
    if (countLeads && countNewClients) {
      let conv_ev_nc = (countNewClients / countLeads) * 100;
      setTxConvTotal(conv_ev_nc);
    }
  }, [countLeads, countNewClients]);

  useEffect(() => {
    if (
      indexAppoitments &&
      indexAppoitments.data &&
      indexAppoitments.data.length > 0
    ) {
      let resultado = {
        origens: {},
        anuncios: {},
        newClients: {},
        countEva: 0,
        countNewCli: 0,
        totalNewCli: 0,
      };

      indexAppoitments.data.forEach((anunciodata) => {
        let origem = anunciodata.Origem || "Desconhecido";

        if (resultado.origens[origem]) {
          resultado.origens[origem] += 1;
        } else {
          resultado.origens[origem] = 1;
        }

        // Tratamento de Anúncio
        let anuncio = anunciodata.Anuncio || "Desconhecido";
        let valor = parseFloat(anunciodata.ValeuProspect) || 0;

        if (resultado.anuncios[anuncio]) {
          resultado.anuncios[anuncio].count += 1;
          resultado.anuncios[anuncio].sum += valor;
        } else {
          resultado.anuncios[anuncio] = {
            count: 1,
            sum: valor,
          };
        }

        // Tratamento de Avaliações
        if (anunciodata.ValeuProspect > 0) {
          resultado.countEva += 1;
        }

        let newClient = anunciodata.Origem || "Desconhecido";

        if (anunciodata.StatusId === 7) {
          console.log(anunciodata);
          if (resultado.newClients[newClient]) {
            resultado.newClients[newClient].count += 1;
            resultado.newClients[newClient].sum += valor;
            resultado.totalNewCli += valor;
          } else {
            resultado.newClients[newClient] = {
              count: 1,
              sum: valor,
            };
            resultado.totalNewCli += valor;
          }
          console.log(resultado.newClients[newClient]);

          resultado.countNewCli += 1;
        }
      });
      setCountAppointments(indexAppoitments.data.length);
      setCountEvaluations(resultado.countEva);
      setCountNewClients(resultado.countNewCli);
      setIndexNewClients(resultado.newClients);
      setTotalNewClients(resultado.totalNewCli);
    } else {
      setCountAppointments(0);
      setCountEvaluations(0);
      setCountNewClients(0);
      setIndexNewClients({});
      setTotalNewClients(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexAppoitments]);

  useEffect(() => {
    if (indexLeads && indexLeads.data && indexLeads.data.length > 0) {
      setCountLeads(indexLeads.data.length);
      let resultado = {
        total: 0,
        origens: {},
        anuncios: {},
      };

      indexLeads.data.forEach((leadsData) => {
        let origem = leadsData.Origem || "Desconhecido";

        resultado.total += leadsData.count;
        if (resultado.origens[origem]) {
          resultado.origens[origem] += leadsData.count;
        } else {
          resultado.origens[origem] = leadsData.count;
        }

        // Tratamento de Leads
        let anuncio = leadsData.Anuncio || "Desconhecido";
        let valor = parseFloat(leadsData.ValeuProspect) || 0;

        if (resultado.anuncios[anuncio]) {
          resultado.anuncios[anuncio].count += leadsData.count;
          resultado.anuncios[anuncio].sum += valor;
        } else {
          resultado.anuncios[anuncio] = {
            count: leadsData.count,
            sum: valor,
          };
        }
      });
      setIndexAds(resultado.anuncios);
      setIndexSources(resultado.origens);
      // setIndexSourcesNewClient(resultado.origens);
      setCountLeads(resultado.total);
    } else {
      // setIndexSourcesNewClient({});
      setIndexAds({});
      setIndexSources({});
      setCountLeads(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexLeads]);

  return (
    <ContainerFlex padding="3">
      <h1>Dashboard</h1>
      <Row marginTop="3">
        <Column col="12">
          <Filters filter={handleFilter} />
        </Column>
      </Row>

      <Row marginTop="3">
        <Column col="12" md="3">
          <ContainerFlex bgColor="white" full rounded padding="3">
            <Text tag="h3">{countLeads ? countLeads : 0}</Text>
            <Text>Oportunidades</Text>
          </ContainerFlex>
        </Column>
        <Column col="12" md="3">
          <ContainerFlex bgColor="white" full rounded padding="3">
            <Text tag="h3">{countAppointments ? countAppointments : 0}</Text>
            <Text>Agendamentos</Text>
          </ContainerFlex>
        </Column>
        <Column col="12" md="3">
          <ContainerFlex bgColor="white" full rounded padding="3">
            <Text tag="h3">{countEvaluations ? countEvaluations : 0}</Text>
            <Text>Avaliações</Text>
          </ContainerFlex>
        </Column>
        <Column col="12" md="3">
          <ContainerFlex bgColor="white" full rounded padding="3">
            <Text tag="h3">{countNewClients ? countNewClients : 0}</Text>
            <Text>Fechamentos</Text>
          </ContainerFlex>
        </Column>
      </Row>
      <Row marginTop="3">
        <Column col="12" md="3">
          <ContainerFlex bgColor="white" full rounded padding="3">
            <ContainerFlex row width between>
              <h3>{txConvOpAg.toFixed(2)} %</h3>
              <ContainerFlex
                style={{ width: "40px", height: "40px" }}
                border
                rounded
                center
                middle
              >
                <i className="fas fa-calendar-check"></i>
              </ContainerFlex>
            </ContainerFlex>
            <small>Conversão de Agendamentos</small>
          </ContainerFlex>
        </Column>
        <Column col="12" md="3">
          <ContainerFlex bgColor="white" full rounded padding="3">
            <ContainerFlex row width between>
              <h3>{txConvAgEv.toFixed(2)} %</h3>
              <ContainerFlex
                style={{ width: "40px", height: "40px" }}
                border
                rounded
                center
                middle
              >
                <i className="fas fa-check"></i>
              </ContainerFlex>
            </ContainerFlex>
            <small className="w-100">Conversão de Avaliações</small>
          </ContainerFlex>
        </Column>
        <Column col="12" md="3">
          <ContainerFlex bgColor="white" full rounded padding="3">
            <ContainerFlex row width between>
              <h3>{txConvEvFc.toFixed(2)} %</h3>
              <ContainerFlex
                style={{ width: "40px", height: "40px" }}
                border
                rounded
                center
                middle
              >
                <i className="fas fa-handshake "></i>
              </ContainerFlex>
            </ContainerFlex>
            <Text tag="small">Conversão de Fechamentos</Text>
          </ContainerFlex>
        </Column>
        <Column col="12" md="3">
          <ContainerFlex bgColor="white" full rounded padding="3">
            <ContainerFlex row width between>
              <h4>{formatarReal(totalNewClients)}</h4>
              <ContainerFlex
                style={{ width: "40px", height: "40px" }}
                border
                rounded
                center
                middle
              >
                <i className="fa-solid fa-hand-holding-dollar"></i>
              </ContainerFlex>
            </ContainerFlex>
            <Text tag="small">Valor de Fechamento</Text>
          </ContainerFlex>
        </Column>
      </Row>
      <Row marginTop="3">
        <Column col="12" md="4">
          <Ads list={indexAds} />
        </Column>
        <Column col="12" md="4">
          <Sources list={indexSources} />
        </Column>
        <Column col="12" md="4">
          <SourcesNewClient list={indexNewClients} />
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default DashboardProspects;
